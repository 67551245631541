import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="logo"></div>
      <div className="text">
        <h1>CUSTOM WEB SOLUTIONS</h1>
        <h2>COMING SOON</h2>
        <a href="mailto: charles@grizzcode.com">Email Me</a>
        
      </div>
      
    </div>
  );
}

export default App;
